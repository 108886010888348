.markdown-node, .markdown-node blockquote {
    border-left-width: 5px;
    border-left-style: solid;
    padding-left: 5px;
    margin-left: 5px;
}

.markdown-node {
    border-left-color: #000;
}

.node-block-paragraph {
    border-left-color: rgb(118,72,13);
}

.node-block-heading {
    border-left-color: rgb(167,232,49);
}

.node-block-blockquote {
    border-left-color: rgb(54,144,148);
}

.node-block-orderedlist {
    border-left-color: rgb(19,87,202);
}

.node-block-unorderedlist {
    border-left-color: rgb(167,220,249);
}

.node-block-listitem {
    border-left-color: rgb(41,77,70);
}

.node-block-code {
    border-left-color: rgb(136,233,154);
}

.node-block-table {
    border-left-color: rgb(28,234,249);
}

.node-block-tableheader {
    border-left-color: rgb(142,0,73);
}

.node-block-tablebody {
    border-left-color: rgb(225,198,55);
}

.node-block-tablerow {
    border-left-color: rgb(250,165,102);
}

.node-block-tablecell {
    border-left-color: rgb(253,78,139);
}

.node-inline-plaintext {
    border-left-color: rgb(5,110,18);
}

.node-inline-link {
    border-left-color: rgb(253,89,23);
}

.node-inline-image {
    border-left-color: rgb(188,205,151);
}

.node-inline-emphasis {
    border-left-color: rgb(127,32,172);
}

.node-inline-strikehtrough {
    border-left-color: rgb(250,165,102);
}

.node-inline-inlinecode {
    border-left-color: rgb(243,101,231);
}

.error-node {
    border-left-color: rgb(159,33,8);
}
