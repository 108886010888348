.dnd5e-stat-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.dnd5e-stat-block::before {
    content: ' ';
    display: block;
    background: #E69A28;
    border: 1px solid #000;
    height: 5px;
    padding: 0 10px 0;
    margin: -10px -10px 0;
    box-sizing: initial;
}
.dnd5e-stat-block::after {
    content: ' ';
    display: block;
    background: #E69A28;
    border: 1px solid #000;
    height: 5px;
    padding: 0 10px 0;
    margin: 0 -10px 0;
    box-sizing: initial;
}
.dnd5e-stat-block.single::before {
    width: 50%;
}
.dnd5e-stat-block.single::after {
    width: 50%;
}

.dnd5e-stat-block-content {
    background: #FDF1DC;
    padding: 0.01rem 10px;
    box-shadow: 0 0 1.5em #867453;
}
.dnd5e-stat-block.single .dnd5e-stat-block-content {
    width: 50%;
}
.dnd5e-stat-block.double .dnd5e-stat-block-content {
    width: 100%;
}
.dnd5e-stat-block.double .dnd5e-stat-block-content {
    column-count: 2;
}

hr.dnd5e-stat-block-border {
    display: block;
    background: #E69A28;
    border: 1px solid #000;
    height: 5px;
    padding: 0 10px 0;
    /*margin: -10px -10px 0;*/
    box-sizing: initial;
}

.dnd5e-stat-block .tapered-rule {
    display: block;
    width: 100%;
    height: 5px;
    border: none;
    color: #922610;
    fill: #922610;
    stroke: #922610;
    margin: 0.5rem 0;
}

.dnd5e-stat-block h6 {
    font-family: 'Libre Baskerville', 'Lora', 'Calisto MT', 'Bookman Old Style', Bookman, 'Goudy Old Style', Garamond, 'Hoefler Text', 'Bitstream Charter', Georgia, serif;
    color: #922610;
    font-size: 23px;
    line-height: 1.2em;
    margin: 10px 0 0;
    letter-spacing: 1px;
    font-variant: small-caps;
    font-weight: bold;
}
.dnd5e-stat-block h6 + p {
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    line-height: 1.2em;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.dnd5e-stat-block .property {
    margin: 0;
    color: #922610;
    font-size: 13.5px;
    text-indent: -1em;
    padding-left: 1.1em;
    line-height: 1.4em;
}
.dnd5e-stat-block .property strong {
    color: #7A200D;
}

.dnd5e-stat-block .abilities {
    text-align: center;
    color: #922610;
}
.dnd5e-stat-block .abilities>div {
    display: inline-block;
    vertical-align: middle;
    width: 15.5%;
    min-width: 40px;
    font-size: 12px;
    line-height: 1em;
}
.dnd5e-stat-block .abilities strong {
    margin: 10px 0 2px;
    font-size: 14px;
    line-height: 1.2em;
    text-transform: uppercase;
    color: #7A200D;
}
.dnd5e-stat-block .abilities p {
    margin: 0;
    line-height: 1.2em;
}

.dnd5e-stat-block .subheading {
    border-bottom: 1px solid #7A200D;
    color: #7A200D;
    font-size: 21px;
    font-variant: small-caps;
    font-weight: normal;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    text-indent: 5px;
}

.dnd5e-stat-block .power strong:nth-child(1) {
    font-style: italic;
}

.dnd5e-stat-block .block + .block::before {
    display: block;
    width: 100%;
    height: 30px;
    margin-top: -5px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' className='tapered-rule' width='100%25' viewBox='0 0 600 15'%3E%3Cpolyline fill='%23922610' stroke='%23922610' points='0,5 600,10 0,15'%3E%3C/polyline%3E%3C/svg%3E");
}