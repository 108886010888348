#body blockquote {
    padding-left: 1em;
    border-left: 2px #CCC solid;
}

@media print {
    #root .App-container {
        padding-top: 0;
        margin-left: 0px;
        margin-right: 0px;
    }

    #body {
        width: 100%;
    }

    #block-actions {
        display: none !important;
    }

    #toc {
        display: none;
    }

    #title button {
        display: none;
    }

    #tags {
        display: none;
    }

    .media {
        display: none;
    }
}