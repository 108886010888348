.toc-list {
    list-style-type: none;
    padding-left: 1em;
    border-left: 1px solid #DDD;
}

.toc-list-main {
    position: sticky;
    top: 56px;
    margin-top: 1rem;
    margin-left: 0;
    padding-bottom: 4rem;
    border-left-style: none;
    height: 100vh;
    overflow-y: scroll;
}

.toc-list button {
    font-size: 1em;
    text-decoration: none;
    background: none !important;
    border: none;
    padding: 0!important;
    color: var(--bs-link-color);
    cursor: pointer;
    text-align: left;
}
.toc-list button:hover {
    text-decoration: underline;
}

.toc-list-main > li > button {
    font-size: 1.25em;
}

.toc-list button.visible {
    font-weight: bold !important;
}