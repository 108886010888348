.media-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 12px;
}

@media (max-width: 768px) {
    .media-gallery {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 576px) {
    .media-gallery {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.media-gallery-image {
    cursor: pointer;
    width: 100%;
    aspect-ratio: 1/1;
}

.media-gallery-image img {
    grid-area: 1 / 1 / 2 / 2;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/*
with 12px gap
  xs (<576) sm (<768) md (<992)
1       576       768       992
2       282       378       490
3       184       248       322
4       135       183       239
5                 144       188
6                           155
*/