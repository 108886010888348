.web-hide {
    display: none;
}

@media print {
    .print-show {
        display: revert !important;
        break-after: page;
    }

    .print-hide {
        display: none;
    }
}