.neutral-stat-block {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.neutral-stat-block {
    border-top: 5px solid black;
    border-bottom: 5px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.neutral-stat-block-content {
    background: #EEEEEE;
    padding: 0.01rem 10px;
}
.neutral-stat-block.single {
    width: 50%;
}
.neutral-stat-block.double .neutral-stat-block-content {
    column-count: 2;
}

.neutral-stat-block h6 {
    color: black;
    font-size: 23px;
    line-height: 1.2em;
    margin: 10px 0 0;
    letter-spacing: 1px;
    font-variant: small-caps;
    font-weight: bold;
}
.neutral-stat-block h6 + p {
    font-weight: normal;
    font-style: italic;
    font-size: 12px;
    line-height: 1.2em;
    margin: 0 0 10px;
    text-transform: capitalize;
}

.neutral-stat-block .property {
    margin-bottom: 0;
}
.neutral-stat-block .property:last-of-type {
    margin-bottom: 0.25rem;
}

.neutral-stat-block .subheading {
    color: black;
    font-size: 21px;
    font-variant: small-caps;
    font-weight: normal;
    margin: 0;
    padding: 10px 0 0 0;
}

.neutral-stat-block .power strong:nth-child(1) {
    font-style: italic;
}

.neutral-stat-block .block + .block {
    border-top: 3px solid black;
}