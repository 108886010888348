html, body, #root {
    margin:0px;
    height:100%;
}

.rendered blockquote {
    border-left: 5px solid black;
    margin-left: 0;
    padding-left: 30px;
    margin-top: 0;
    margin-bottom: 0;
}
.rendered blockquote:has(+ blockquote) {
    margin-bottom: 16px;
}

.rendered p {
    margin-top: 0;
    margin-bottom: 0;
}
.rendered p:has(+ p) {
    margin-bottom: 16px;
}
